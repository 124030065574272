import { AutomatedLabResultTest, AutomatedLabResultValue } from '../labs';

export const CLIENT_APP_SETTINGS_COLLECTION_NAME = 'clientApp.settings';

export const CLIENT_APP_SETTINGS_GLOBAL_ID = 'global';
export const CLIENT_APP_SETTINGS_MEDICAL_RECORDS_ID = 'medicalRecords';
export const CLIENT_APP_SETTINGS_CANCELLATION_ID = 'cancellationReasons';
export const CLIENT_APP_SETTINGS_REMINDERS_ID = 'reminders';
export const CLIENT_APP_SETTINGS_LABS_ID = 'labs';

export interface GlobalSettingsInput {
    useClientAppReschedule: boolean;
    useClientAppCancel: boolean;
    hideVisitTimeScheduleNames?: ReadonlyArray<string>;
}

export type GlobalSettings = GlobalSettingsInput & {
    _id: typeof CLIENT_APP_SETTINGS_GLOBAL_ID;
};

export const defaultGlobalSettings: GlobalSettings = {
    _id: CLIENT_APP_SETTINGS_GLOBAL_ID,
    useClientAppReschedule: false,
    useClientAppCancel: false,
    hideVisitTimeScheduleNames: [],
};

export interface GlobalSettingsQueryResult {
    globalSettings: GlobalSettings;
}
export interface MedicalRecordsReason {
    value: string;
    label: string;
    requiresExplanation?: boolean;
}

export interface MedicalRecordsSettingsInput {
    reasons: readonly MedicalRecordsReason[];
    types: readonly { label: string; value: string; sortOrder: number }[];
}
export type MedicalRecordsSettings = MedicalRecordsSettingsInput & {
    _id: typeof CLIENT_APP_SETTINGS_MEDICAL_RECORDS_ID;
};

export const defaultMedicalRecordsSettings: MedicalRecordsSettings = {
    _id: CLIENT_APP_SETTINGS_MEDICAL_RECORDS_ID,
    reasons: [
        {
            value: 'insurance',
            label: 'Insurance Purposes',
        },
        {
            value: 'personal',
            label: 'Personal Reasons',
        },
        {
            value: 'sendToGeneralPractitioner',
            label: 'Sending to my General Practitioner',
        },
        {
            value: 'switchingVeterinarians',
            label: 'Switching Veterinarians',
        },
        {
            value: 'verification',
            label: 'Verification for Apartment/Boarding/Daycare/Grooming',
        },
        {
            value: 'other',
            label: 'Other',
            requiresExplanation: true,
        },
    ],
    types: [
        {
            value: 'fullMedicalHistory',
            label: 'Full Medical History',
            sortOrder: 1,
        },
        { value: 'visitSummary', label: 'Visit Summary', sortOrder: 2 },
        {
            value: 'vaccinationRecords',
            label: 'Vaccination Records',
            sortOrder: 3,
        },
        {
            value: 'rabiesCertificate',
            label: 'Rabies Certificate',
            sortOrder: 4,
        },
    ],
};

export interface MedicalRecordsSettingsQueryResult {
    medicalRecordsSettings: MedicalRecordsSettings;
}

export interface CancellationReason {
    value: string;
    label: string;
    requiresExplanation?: boolean;
    obsolete?: boolean;
}

export interface CancellationSettingsInput {
    cancellationReasons: readonly CancellationReason[];
}
export type CancellationSettings = CancellationSettingsInput & {
    _id: typeof CLIENT_APP_SETTINGS_CANCELLATION_ID;
};

export const defaultCancellationSettings: CancellationSettings = {
    _id: CLIENT_APP_SETTINGS_CANCELLATION_ID,
    cancellationReasons: [
        {
            value: 'condition_improved',
            label: 'My pet’s condition improved.',
        },
        {
            value: 'regular_vet',
            label: 'My regular vet was able to see me.',
        },
        {
            value: 'different_vet_clinic',
            label: 'I’m taking my pet to another general practice vet clinic. ',
        },
        {
            value: 'er',
            label: 'I’m taking my pet to the ER.',
        },
        {
            value: 'cost',
            label: 'I’m concerned about the cost.',
        },
        {
            value: 'not_able',
            label: 'I’m no longer able to make my appointment.',
            requiresExplanation: true,
        },
        {
            value: 'different_bond_clinic',
            label: 'I want to visit a different Bond Vet location.',
            obsolete: true,
        },
        {
            value: 'different_bond_vet',
            label: 'I want to select a different Bond Vet veterinarian.',
            obsolete: true,
        },
        {
            value: 'other',
            label: 'Other',
            requiresExplanation: true,
        },
    ],
};

export interface CancellationSettingsQueryResult {
    cancellationSettings: CancellationSettings;
}

export interface ImmunizationProtocol {
    protocolId: string;
    duplicatedImmunizationIds: readonly string[];
}

export interface RemindersSettingsInput {
    wellnessProtocolId: string;
    immunizationProtocols: readonly ImmunizationProtocol[];
    rabiesVaccineProtocolIds: readonly string[];
    coreVaccineProtocolIds: readonly string[];
    diagnosticProtocolIds: readonly string[];
    preventativeProtocolIds: readonly string[];
    followupProtocolIds: readonly string[];
    upcomingRemindersDryRun: boolean;
    overdueRemindersDryRun: boolean;
}

export type RemindersSettings = RemindersSettingsInput & {
    _id: typeof CLIENT_APP_SETTINGS_REMINDERS_ID;
};

export const defaultRemindersSettings: RemindersSettings = {
    _id: CLIENT_APP_SETTINGS_REMINDERS_ID,
    upcomingRemindersDryRun: true,
    overdueRemindersDryRun: true,
    immunizationProtocols: [],
    wellnessProtocolId: '',
    rabiesVaccineProtocolIds: [],
    coreVaccineProtocolIds: [],
    diagnosticProtocolIds: [],
    preventativeProtocolIds: [],
    followupProtocolIds: [],
};

export interface RemindersSettingsQueryResult {
    reminderSettings: RemindersSettings;
}

export interface LabsSettingsInput {
    automatedResultTests: readonly AutomatedLabResultTest[];
    automatedResultValues: readonly AutomatedLabResultValue[];
    smallSampleResultValues: readonly AutomatedLabResultValue[];
    resultCommentBodies: {
        pdfReady: string;
        pdfNotReady: string;
    };
    // Requirement that at least one of the results we communicate about should have been updated within this timeframe
    individualResultUpdateMinutes: number;
}

export type LabsSettings = LabsSettingsInput & {
    _id: typeof CLIENT_APP_SETTINGS_LABS_ID;
};

export const defaultLabsSettings: LabsSettings = {
    _id: CLIENT_APP_SETTINGS_LABS_ID,
    automatedResultTests: [],
    automatedResultValues: [],
    smallSampleResultValues: [],
    resultCommentBodies: {
        pdfReady:
            'Negative lab result was sent to our automated email system with link to PDF in Pet Portal',
        pdfNotReady:
            'Negative lab result was sent to our automated email system',
    },
    individualResultUpdateMinutes: 720, // 12 hours
};

export type ClientAppSettings =
    | GlobalSettings
    | MedicalRecordsSettings
    | CancellationSettings
    | RemindersSettings
    | LabsSettings;
