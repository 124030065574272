import * as React from 'react';
import useProviders from 'hooks/useProviders';
import useTranslate from 'hooks/useTranslate';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import type { Provider } from 'api/providers/queries';
import {
    Location,
    locationsQuery,
    LocationsQueryResult,
} from 'api/locations/queries';
import useViewerSettings, {
    ViewerSettingsKey,
    ViewerSettingsNS,
} from 'hooks/useViewerSettings';
import { ProviderRightValue } from '@bondvet/types/providers';
import CircularProgress from '@mui/material/CircularProgress';
import { LocationsSelect, ProvidersSelect } from 'components/LookupSelect';
import { DateFilterType } from 'components/DateFilter/types';
import DateFilter from 'components/DateFilter';
import mergeWith from 'lodash/mergeWith';
import isNumber from 'lodash/isNumber';
import ActionButton from 'components/ActionButton';
import EncounterTypeSelect from './components/EncounterTypeSelect/EncounterTypeSelect';
import { EncounterStats, SelectOption } from './types';
import styles from './Encounters.module.scss';
import EncountersList from './components/EncountersList';
import {
    encounterTypesQuery,
    EncounterTypesQueryResult,
} from '../../api/encounters/queries';
import BulkSignDialog from './components/BulkSignDialog';

const Encounters: React.FunctionComponent = () => {
    const translate = useTranslate();
    const [bulkSignDialogOpen, setBulkSignDialogOpen] = React.useState(false);
    const [loadAllData, setLoadAllData] = React.useState(false);
    const [selectedLocations, setSelectedLocations] = React.useState<
        Location[]
    >([]);
    const [selectedEncounterTypes, setSelectedEncounterTypes] = React.useState<
        SelectOption[]
    >([]);
    const [selectedLocationsRestored, setSelectedLocationsRestored] =
        React.useState(false);

    const [selectedProviders, setSelectedProviders] = React.useState<
        Provider[]
    >([]);
    const [selectedProvidersRestored, setSelectedProvidersRestored] =
        React.useState(false);
    const [todayStats, setTodayStats] = React.useState<Partial<EncounterStats>>(
        {
            count: 0,
            allLoaded: true,
        },
    );
    const [pastStats, setPastStats] = React.useState<Partial<EncounterStats>>({
        count: 0,
        allLoaded: true,
    });
    const [futureStats, setFutureStats] = React.useState<
        Partial<EncounterStats>
    >({
        count: 0,
        allLoaded: true,
    });

    const [refreshTrigger, setRefreshTrigger] = React.useState(0);

    const combinedStats: Partial<EncounterStats> = React.useMemo(() => {
        if (
            todayStats.allLoaded &&
            pastStats.allLoaded &&
            futureStats.allLoaded
        ) {
            return mergeWith(
                {},
                todayStats,
                pastStats,
                futureStats,
                (objValue, srcValue) => {
                    if (isNumber(objValue) && isNumber(srcValue)) {
                        return objValue + srcValue;
                    }
                    return undefined;
                },
            );
        }
        return {
            locations: {},
            providers: {},
            encounterTypes: {},
            count:
                (todayStats?.count || 0) +
                (pastStats?.count || 0) +
                (futureStats?.count || 0),
            allLoaded: false,
        };
    }, [futureStats, pastStats, todayStats]);

    const viewerSettings = useViewerSettings();

    const locationsQueryResult = useQuery<LocationsQueryResult>(
        locationsQuery,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const providersQueryResult = useProviders();

    const [encounterTypes, setEncounterTypes] = React.useState<SelectOption[]>(
        [],
    );
    useQuery<EncounterTypesQueryResult>(encounterTypesQuery, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const mappedEncounterTypes = data.encounterTypes
                .map(({ id, name }) => ({
                    value: id,
                    label: name,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setEncounterTypes(mappedEncounterTypes);
        },
    });

    const [currentDateFilterType, setCurrentDateFilterType] =
        React.useState<DateFilterType>(DateFilterType.all);

    // restore selectedProviders and selectedLocations from localStorage
    // as soon as all required queries are executed
    React.useEffect(() => {
        if (!viewerSettings.loading && !viewerSettings.error) {
            if (
                !providersQueryResult.loading &&
                !providersQueryResult.error &&
                providersQueryResult.providers
            ) {
                // restore values only until rights are loaded
                if (!selectedProvidersRestored) {
                    if (viewerSettings.rights !== null) {
                        // stop updating as soon as provider rights are loaded
                        setSelectedProvidersRestored(true);
                    }
                    // restrict to own records as long as provider rights are not loaded
                    if (
                        viewerSettings.rights === null ||
                        viewerSettings.rights?.vetspireExtension_encounters !==
                            ProviderRightValue.enabled_allRecords
                    ) {
                        setSelectedProviders(
                            providersQueryResult.providers.filter(
                                (provider) =>
                                    provider.id === viewerSettings.viewer?.id,
                            ),
                        );
                    } else {
                        const newSelectedProviderIds = viewerSettings.getItem<
                            string[]
                        >(
                            ViewerSettingsNS.encounters,
                            ViewerSettingsKey.selectedProviderIds,
                            [],
                        );

                        setSelectedProviders(
                            providersQueryResult.providers.filter((provider) =>
                                newSelectedProviderIds.includes(provider.id),
                            ),
                        );
                    }
                }
            }

            if (
                !locationsQueryResult.loading &&
                !locationsQueryResult.error &&
                locationsQueryResult.data?.locations
            ) {
                // restore values only once
                if (!selectedLocationsRestored) {
                    setSelectedLocationsRestored(true);

                    // restore values only once
                    const newSelectedLocationIds = viewerSettings.getItem<
                        string[]
                    >(
                        ViewerSettingsNS.encounters,
                        ViewerSettingsKey.selectedLocationIds,
                        [],
                    );

                    setSelectedLocations(
                        locationsQueryResult.data?.locations.filter(
                            (location) =>
                                newSelectedLocationIds.includes(location.id),
                        ),
                    );
                }
            }
        }
    }, [
        locationsQueryResult,
        selectedLocationsRestored,
        providersQueryResult,
        selectedProvidersRestored,
        viewerSettings,
    ]);

    const onChangeLocations = (locations: Location[]) => {
        setSelectedLocations(locations);
        setLoadAllData(false);
    };

    const onChangeProviders = (providers: Provider[]) => {
        viewerSettings.setItem<string[]>(
            ViewerSettingsNS.encounters,
            ViewerSettingsKey.selectedProviderIds,
            providers.map((provider) => provider.id),
        );
        setSelectedProviders(providers);
    };

    const selectedLocation =
        selectedLocations.length === 1 ? selectedLocations[0] : undefined;

    const handleLoadAll = () => {
        setLoadAllData(true);
    };

    const handleBulkSignClose = (refresh?: boolean) => {
        setBulkSignDialogOpen(false);
        if (refresh) {
            setLoadAllData(false);
            setRefreshTrigger((prev) => prev + 1);
        }
    };

    const canBulkSign =
        viewerSettings?.rights?.vetspireExtension_bulkSignEncounters ===
        ProviderRightValue.enabled;

    return (
        <div className={styles.container}>
            <div className={classnames(styles.flyoutBlock, styles.first)}>
                <div className={styles.flyoutTitle}>
                    {translate('vetspireExtension.encounters.title')}
                </div>
                {canBulkSign && (
                    <ActionButton
                        onClick={() => setBulkSignDialogOpen(true)}
                        className={styles.bulkSignButton}
                    >
                        {translate('vetspireExtension.encounters.bulkSign')}
                    </ActionButton>
                )}
                {!loadAllData && (
                    <ActionButton
                        onClick={handleLoadAll}
                        className={styles.loadAllButton}
                    >
                        {translate('vetspireExtension.encounters.loadAll')}
                    </ActionButton>
                )}
                {loadAllData && !combinedStats.allLoaded && (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                )}
            </div>
            <div className={styles.flyoutBlock}>
                <LocationsSelect
                    locationsQueryResult={locationsQueryResult}
                    selectedLocations={selectedLocations}
                    onChange={onChangeLocations}
                    isDisabled={
                        viewerSettings.rights === null ||
                        viewerSettings.rights.vetspireExtension_encounters !==
                            ProviderRightValue.enabled_allRecords
                    }
                    stats={combinedStats.locations}
                />
            </div>
            <div className={styles.flyoutBlock}>
                <EncounterTypeSelect
                    selectedEncounterTypes={selectedEncounterTypes}
                    setSelectedEncounterTypes={setSelectedEncounterTypes}
                    options={encounterTypes || []}
                />
            </div>
            <div className={styles.flyoutBlock}>
                <ProvidersSelect
                    locationsQueryResult={locationsQueryResult}
                    providersQueryResult={providersQueryResult}
                    selectedProviders={selectedProviders}
                    vetsOnly
                    onChange={onChangeProviders}
                    isDisabled={
                        viewerSettings.rights === null ||
                        viewerSettings.rights.vetspireExtension_encounters !==
                            ProviderRightValue.enabled_allRecords
                    }
                    addLocationGroup={false}
                    addOtherGroup={false}
                    stats={combinedStats.providers}
                />
            </div>
            <div
                className={classnames(
                    styles.flyoutBlockFullWidth,
                    styles.noHorizontal,
                )}
            >
                <DateFilter
                    currentDateFilterType={currentDateFilterType}
                    dateStats={{
                        all: combinedStats.allLoaded
                            ? combinedStats.count
                            : `${combinedStats.count}+`,
                        today: todayStats.allLoaded
                            ? todayStats.count
                            : `${todayStats.count}+`,
                        past: pastStats.allLoaded
                            ? pastStats.count
                            : `${pastStats.count}+`,
                        future: futureStats.allLoaded
                            ? futureStats.count
                            : `${futureStats.count}+`,
                    }}
                    translatePrefix="vetspireExtension.encounters.startDate"
                    showFuture={false}
                    onChange={(dateFilterType) =>
                        setCurrentDateFilterType(dateFilterType)
                    }
                />
            </div>
            <div
                className={classnames(
                    styles.flyoutBlock,
                    styles.noVertical,
                    styles.noHorizontal,
                    styles.encounterList,
                )}
            >
                {viewerSettings.loading ? (
                    <div className={styles.loading}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        {(currentDateFilterType === DateFilterType.all ||
                            currentDateFilterType === DateFilterType.today) && (
                            <EncountersList
                                dateFilterType={DateFilterType.today}
                                selectedLocation={selectedLocation}
                                selectedProviders={selectedProviders}
                                selectedEncounterTypes={selectedEncounterTypes}
                                setEncounterStats={setTodayStats}
                                loadAllData={loadAllData}
                                onRefresh={refreshTrigger}
                            />
                        )}
                        {(currentDateFilterType === DateFilterType.all ||
                            currentDateFilterType === DateFilterType.past) && (
                            <EncountersList
                                dateFilterType={DateFilterType.past}
                                selectedLocation={selectedLocation}
                                selectedProviders={selectedProviders}
                                selectedEncounterTypes={selectedEncounterTypes}
                                setEncounterStats={setPastStats}
                                loadAllData={loadAllData}
                                onRefresh={refreshTrigger}
                            />
                        )}
                        {currentDateFilterType === DateFilterType.all && (
                            <EncountersList
                                dateFilterType={DateFilterType.future}
                                selectedLocation={selectedLocation}
                                selectedProviders={selectedProviders}
                                selectedEncounterTypes={selectedEncounterTypes}
                                setEncounterStats={setFutureStats}
                                loadAllData={loadAllData}
                                onRefresh={refreshTrigger}
                            />
                        )}
                    </div>
                )}
            </div>
            {bulkSignDialogOpen && (
                <BulkSignDialog onClose={handleBulkSignClose} />
            )}
        </div>
    );
};

export default Encounters;
