import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { isBefore } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import ProvidersSelect from 'components/LookupSelect/ProvidersSelect';
import useProviders from 'hooks/useProviders';
import useBulkSignEncounters from '../../hooks/useBulkSignEncounters';
import styles from './BulkSignDialog.module.scss';

type BulkSignDialogProps = {
    onClose: (refresh?: boolean) => void;
};

const BulkSignDialog: React.FunctionComponent<BulkSignDialogProps> = ({
    onClose,
}) => {
    const translate = useTranslate();
    const providersQueryResult = useProviders();

    const {
        bulkSignEncounters,
        loading,
        error,
        formValues,
        editFormValues,
        canOnlySignOwn,
        signAmount,
    } = useBulkSignEncounters();

    const showDateError = React.useMemo(() => {
        return (
            formValues.start &&
            formValues.end &&
            isBefore(formValues.end, formValues.start)
        );
    }, [formValues.end, formValues.start]);

    const handleSubmit = React.useCallback(async () => {
        const success = await bulkSignEncounters();
        if (success) {
            onClose(true);
        }
    }, [bulkSignEncounters, onClose]);

    const disabled =
        showDateError ||
        (!canOnlySignOwn && !formValues.teamMember.length) ||
        loading ||
        signAmount === 0;
    return (
        <Dialog open onClose={() => onClose()}>
            <DialogTitle className={styles.title}>
                {translate('vetspireExtension.encounters.bulkSignModal.title')}
                <IconButton
                    onClick={() => onClose()}
                    className={styles.closeIcon}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div>
                        <div className={styles.datePickers}>
                            <div>
                                <div
                                    className={classnames({
                                        [styles.disabled]: formValues.signAll,
                                    })}
                                >
                                    {translate(
                                        'vetspireExtension.encounters.bulkSignModal.start',
                                    )}
                                </div>
                                <DatePicker
                                    value={formValues.start}
                                    disabled={formValues.signAll}
                                    className={styles.picker}
                                    onChange={(start) =>
                                        editFormValues({
                                            start,
                                        })
                                    }
                                />
                            </div>
                            <div
                                className={classnames(styles.to, {
                                    [styles.disabled]: formValues.signAll,
                                })}
                            >
                                {translate(
                                    'vetspireExtension.encounters.bulkSignModal.to',
                                )}
                            </div>
                            <div>
                                <div
                                    className={classnames({
                                        [styles.disabled]: formValues.signAll,
                                    })}
                                >
                                    {translate(
                                        'vetspireExtension.encounters.bulkSignModal.end',
                                    )}
                                </div>
                                <DatePicker
                                    value={formValues.end}
                                    disabled={formValues.signAll}
                                    className={styles.picker}
                                    onChange={(end) =>
                                        editFormValues({
                                            end,
                                        })
                                    }
                                    minDate={formValues.start ?? undefined}
                                />
                            </div>
                        </div>
                        {showDateError && (
                            <div className={styles.error}>
                                {translate(
                                    'vetspireExtension.encounters.bulkSignModal.endDateError',
                                )}
                            </div>
                        )}
                    </div>
                </LocalizationProvider>
                <FormControlLabel
                    className={styles.showInactivePlans}
                    control={
                        <Checkbox
                            color="default"
                            checked={formValues.signAll}
                            onChange={(value) =>
                                editFormValues({
                                    signAll: value.target.checked,
                                })
                            }
                        />
                    }
                    label={translate(
                        'vetspireExtension.encounters.bulkSignModal.signAll',
                    )}
                />
                {!canOnlySignOwn && (
                    <div>
                        <div className={styles.label}>
                            {translate(
                                'vetspireExtension.encounters.bulkSignModal.selectTeamMember',
                            )}
                        </div>
                        <ProvidersSelect
                            providersQueryResult={providersQueryResult}
                            selectedProviders={formValues.teamMember}
                            onChange={(providers) =>
                                editFormValues({
                                    teamMember:
                                        providers.length > 0 ? providers : [],
                                })
                            }
                            addLocationGroup={false}
                            addOtherGroup={false}
                            isMulti={false}
                            vetsOnly
                            placeholder={translate(
                                'vetspireExtension.encounters.bulkSignModal.selectTeamMember',
                            )}
                        />
                    </div>
                )}
                {signAmount !== undefined && (
                    <div className={styles.signAmount}>
                        {translate(
                            'vetspireExtension.encounters.bulkSignModal.signAmount',
                            {
                                amount: signAmount,
                            },
                        )}
                    </div>
                )}
                <div className={styles.actionButtons}>
                    <Button
                        color="primary"
                        variant="text"
                        className={styles.cancel}
                        onClick={() => onClose()}
                    >
                        {translate(
                            'vetspireExtension.encounters.bulkSignModal.close',
                        )}
                    </Button>
                    <Button
                        onClick={() => handleSubmit()}
                        className={classnames(styles.submit, {
                            [styles.disabled]: disabled,
                        })}
                        disabled={disabled}
                    >
                        {loading ? (
                            <CircularProgress
                                size={20}
                                className={styles.loading}
                            />
                        ) : (
                            translate(
                                'vetspireExtension.encounters.bulkSignModal.submit',
                            )
                        )}
                    </Button>
                </div>
                {error && <div className={styles.error}>{error}</div>}
            </DialogContent>
        </Dialog>
    );
};

export default BulkSignDialog;
