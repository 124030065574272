import * as React from 'react';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { FormControl, FormLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import classnames from 'classnames';
import useQuestionnaireForm from './hooks/useQuestionnaireForm';
import styles from './BioQuestionnaire.module.scss';
import Success from './components/Success';

export default function BioQuestionnaire() {
    const translate = useTranslate();
    const [showSubmitModal, setShowSubmitModal] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const {
        loading,
        questions,
        formValues,
        editFormValues,
        handleSave,
        validationResult,
    } = useQuestionnaireForm();

    const handleSubmit = React.useCallback(async () => {
        if (validationResult) {
            setShowErrors(true);
            setShowSubmitModal(false);
        } else {
            await handleSave(true);
            setSubmitted(true);
        }
    }, [handleSave, validationResult]);

    if (submitted) {
        return <Success />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <h1>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.title',
                        undefined,
                        { renderInnerHtml: true },
                    )}
                </h1>
                <p>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.text',
                        undefined,
                        { renderInnerHtml: true },
                    )}
                </p>
                <p>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.note',
                        undefined,
                        { renderInnerHtml: true },
                    )}
                </p>
            </div>
            <div>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <div className={styles.questions}>
                        {questions.map(
                            (
                                { id, maxCharacters, placeholder, label },
                                index,
                            ) => {
                                const aboveMaxChars =
                                    formValues[id]?.length > maxCharacters;
                                return (
                                    <FormControl
                                        key={id}
                                        className={styles.question}
                                    >
                                        <FormLabel
                                            className={styles.questionLabel}
                                        >
                                            {`${index + 1}. ${label}`}
                                        </FormLabel>
                                        <TextField
                                            multiline
                                            variant="outlined"
                                            placeholder={placeholder}
                                            value={formValues[id]}
                                            onChange={(e) => {
                                                editFormValues({
                                                    [id]: e.target.value,
                                                });
                                            }}
                                            slotProps={{
                                                input: {
                                                    className: classnames(
                                                        styles.questionInput,
                                                        {
                                                            [styles.error]:
                                                                showErrors &&
                                                                validationResult?.[
                                                                    id
                                                                ],
                                                        },
                                                    ),
                                                },
                                                formHelperText: {
                                                    className: styles.errorText,
                                                },
                                            }}
                                            helperText={
                                                showErrors &&
                                                validationResult?.[id]
                                            }
                                        />
                                        <div className={styles.maxCharacters}>
                                            <span
                                                className={classnames(
                                                    styles.characters,
                                                    {
                                                        [styles.errorText]:
                                                            aboveMaxChars,
                                                    },
                                                )}
                                            >
                                                {formValues[id]?.length ?? 0}
                                            </span>
                                            <span>/{maxCharacters}</span>
                                        </div>
                                    </FormControl>
                                );
                            },
                        )}
                    </div>
                )}
            </div>
            <div className={styles.actionButtons}>
                <Button
                    className={styles.save}
                    variant="outlined"
                    onClick={async () => {
                        await handleSave();
                        setSaved(true);
                        setTimeout(() => {
                            setSaved(false);
                        }, 10000);
                    }}
                >
                    {translate('vetspireExtension.bioQuestionnaire.save')}
                </Button>
                <Button
                    className={styles.submit}
                    variant="contained"
                    onClick={() => setShowSubmitModal(true)}
                >
                    {translate('vetspireExtension.bioQuestionnaire.submit')}
                </Button>
            </div>
            {saved && (
                <div className={styles.saved}>
                    {translate('vetspireExtension.bioQuestionnaire.saved')}
                </div>
            )}
            <Dialog open={showSubmitModal}>
                <DialogTitle>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.modal.title',
                    )}
                </DialogTitle>
                <DialogContent>
                    <div>
                        {translate(
                            'vetspireExtension.bioQuestionnaire.modal.text',
                        )}
                    </div>
                    <DialogActions className={styles.actionButtons}>
                        <Button
                            className={styles.cancel}
                            variant="text"
                            onClick={() => setShowSubmitModal(false)}
                        >
                            {translate(
                                'vetspireExtension.bioQuestionnaire.modal.cancel',
                            )}
                        </Button>
                        <Button
                            className={styles.submit}
                            variant="contained"
                            disabled={loading}
                            onClick={handleSubmit}
                        >
                            {translate(
                                'vetspireExtension.bioQuestionnaire.modal.submit',
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
}
