import * as React from 'react';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import Button from '@mui/material/Button';
import { closeFlyout } from 'lib/vetspireActions';
import styles from './Success.module.scss';

export default function Success() {
    const translate = useTranslate();
    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <h1>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.success.title',
                    )}
                </h1>
                <p>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.success.text',
                    )}
                </p>
                <p>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.success.note',
                    )}
                </p>
            </div>
            <Button
                onClick={() => closeFlyout()}
                className={styles.closeButton}
            >
                {translate('vetspireExtension.bioQuestionnaire.success.close')}
            </Button>
        </div>
    );
}
