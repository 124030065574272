import type { UpdateFilter } from 'mongodb';
import type { Projection } from '../common';
import {
    LEAD_IMPORT_CUSTOM_ATTRIBUTE_TARGETS,
    LEAD_IMPORT_PET_COLUMN_TARGETS,
    LEAD_IMPORT_SUBSCRIPTION_PREFERENCES_TARGETS,
    LEAD_SUBSCRIPTION_STATUS_CHANGES_FIELDS,
    type PhoneNumberData,
    type LeadSubscriptionStatusChangeField,
} from '../communication';
import type { LeadSubscriptionStatusUpdateSource } from '../logging';
import type { PickStartsWith } from '../util';

export interface NewsletterList {
    id: string;
    name: string;
}

export interface LeadInput {
    email: string;
    list: string | null;
}

export interface Signup {
    list: string | null;
    date: Date;
}

// new attributes we introduced in the Braze migration
export interface LeadCustomAttributes {
    firstName?: string;
    lastName?: string;
    dob?: string;
    city?: string;
    gender?: string;
    country?: string;
    phone?: string | null;
    address?: string;
    addressExtra?: string;
    preferredLocation?: string;
    region?: string;
    state?: string;
    zipCode?: string;
    nearestClinic?: string;
    source?: string;
}

export interface LeadPetAttributes {
    petName?: string;
    species?: string;
    petAge?: number;
    petBirthday?: string;
    petWeight?: string;
    petBreed?: string;
    petDeceased?: boolean;
    petSex?: string;
    microchipped?: boolean;
    spay_neuter?: boolean;
    lastDental?: string;
}

export interface LeadPet extends LeadPetAttributes {
    _id: string;
    leadId: string;
    animalId?: string;
    updatedAt: Date;
    createdAt: Date;
}

export enum SubscriptionStatus {
    subscribed = 'subscribed',
    unsubscribed = 'unsubscribed',
    optedIn = 'opted_in',
}

export const SUBSCRIPTION_STATUS = Object.freeze(SubscriptionStatus);

export type BooleanishSubscriptionStatus = Exclude<
    SubscriptionStatus,
    SubscriptionStatus.optedIn
>;

export interface LeadSubscriptionStatus {
    email_subscribe?: SubscriptionStatus;
    sms_marketing?: boolean;
    sms_transactional?: boolean;
    sms_conversational?: boolean;
}

export type SmsSubscriptionStatus = PickStartsWith<
    LeadSubscriptionStatus,
    'sms'
>;

export type SmsSubscriptionStatusKey = keyof SmsSubscriptionStatus;

export type LeadSubscriptionStatusChanges = Partial<
    Record<LeadSubscriptionStatusChangeField, Date>
>;

export type SmsSubscriptionStatusChanges = PickStartsWith<
    LeadSubscriptionStatusChanges,
    'sms'
>;

export type SmsStatusAndChanges = SmsSubscriptionStatus &
    SmsSubscriptionStatusChanges;

export type LeadAttributes = LeadCustomAttributes & LeadSubscriptionStatus;

export type AdditionalLeadAttributes = Record<string, unknown>;

export type LeadPhoneNumberData = Omit<PhoneNumberData, 'phoneNumber'> & {
    phone?: string | null;
};

export type BrazeLeadSubscriptionStatus = LeadSubscriptionStatus & {
    email?: string;
};

export type Lead = Omit<LeadInput, 'list'> &
    LeadCustomAttributes &
    LeadSubscriptionStatus &
    LeadSubscriptionStatusChanges &
    LeadPhoneNumberData & {
        _id: string;
        hashedEmail: string;
        signedUpAt: Date;
        updatedAt: Date;
        signups: Signup[];
        subscribedLists: string[];
        language?: string;
        syncedWithCustomerIo: boolean;
        syncingWithCustomerIo?: boolean;
        // once a lead has been converted to a client
        // we will store the client's `_id` (from `users` collection)
        // in this attribute
        clientId?: string | null;
        attributes?: AdditionalLeadAttributes;
        _braze?: BrazeLeadSubscriptionStatus;

        /**
         * if we do an .updateMany with changed SMS preferences,
         * we will add a { $inc: {_doNotPropagate: 1}} to the update,
         * so that we can catch this in the update hook and not propagate
         * that update further
         */
        _doNotPropagate?: number;
    };

const LEAD_PROJECTION_FIELDS = [
    '_id',
    'email',
    'signedUpAt',
    'subscribedLists',
    'syncedWithCustomerIo',
    'clientId',
    'attributes',
    'hashedEmail',
    'updatedAt',
    'intlPhoneNumber',
    ...LEAD_IMPORT_CUSTOM_ATTRIBUTE_TARGETS,
    ...LEAD_IMPORT_SUBSCRIPTION_PREFERENCES_TARGETS,
] as const;

const LEAD_PET_PROJECTION_FIELDS = [
    ...LEAD_IMPORT_PET_COLUMN_TARGETS,
    'leadId',
    'animalId',
    '_id',
    'updatedAt',
] as const;

type LeadPetProjectionFields = (typeof LEAD_PET_PROJECTION_FIELDS)[number];
export type LeadPetData = Pick<LeadPet, LeadPetProjectionFields>;

export const LEAD_PET_PROJECTION: Projection =
    LEAD_PET_PROJECTION_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {},
    );

export const LEAD_PROJECTION: Projection = LEAD_PROJECTION_FIELDS.reduce(
    (projection, field) => ({
        ...projection,
        [field]: 1,
    }),
    { _id: 1 },
);

export type LeadProjectionFields = (typeof LEAD_PROJECTION_FIELDS)[number];
export type LeadData = Pick<Lead, LeadProjectionFields>;

const EXPORT_LEAD_PROJECTION_FIELDS = [
    ...LEAD_PROJECTION_FIELDS.filter(
        (attribute) => attribute !== 'syncedWithCustomerIo',
    ),
    ...LEAD_SUBSCRIPTION_STATUS_CHANGES_FIELDS,
] as const;

export const EXPORT_LEAD_PROJECTION: Projection =
    EXPORT_LEAD_PROJECTION_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        { _id: 1 },
    );

export type ExportLeadProjectionFields =
    (typeof EXPORT_LEAD_PROJECTION_FIELDS)[number];
export type ExportLeadData = Pick<Lead, ExportLeadProjectionFields>;

export interface NewsletterSignupEventData {
    email: string;
    timestamp: number;
    timezone: string;
    list: string | null;
}

export const NEWSLETTER_SIGNUP_TRIGGER = 'newsletter_signup';

export interface CustomerIoExportEntryAttributes
    extends Record<string, string | boolean | unknown> {
    createdAt?: Date;
}

export interface CustomerIoExportEntry {
    id: string | null;
    email: string | null;
    cioId: string | null;
    attributes: CustomerIoExportEntryAttributes;
}

export const LEAD_IS_TEST_USER = 'testUser';
export const LEAD_IS_DUPLICATE = 'duplicate';

export interface DeletedLeadCommonData {
    deletedAt: Date;
    reason: typeof LEAD_IS_TEST_USER | typeof LEAD_IS_DUPLICATE;
    cioId?: string;
    mergedIntoCioId?: string;
    mergedIntoId?: string;
    data: Omit<CustomerIoExportEntry, 'id'>;
}

export interface DeletedLead extends DeletedLeadCommonData {
    _id: string;
}

export type LeadDeletionReason = DeletedLead['reason'];

export type SubscriptionStatusChange = {
    /**
     * type of the subscription status change
     */
    type: keyof LeadSubscriptionStatus;
    /**
     * new status
     */
    status: Exclude<SubscriptionStatus, SubscriptionStatus.optedIn>;
    /**
     * when was the action performed?
     */
    timestamp: Date;
    /**
     * source of the change
     */
    source: LeadSubscriptionStatusUpdateSource;
    /**
     * if done in Vetspire, this will hold the provider's ID,
     * who was logged in to Vetspire and performed the change
     */
    vetspireProviderId?: string | null;
    /**
     * if done in Vetspire in the texting pane, this will hold the name the
     * provider typed into the freeform field
     */
    vetspireProviderRecordedName?: string | null;
    /**
     * if they are a client, this will hold their {@link User#_id}
     */
    clientId?: string | null;
    /**
     * if their email subscription status was changed, this
     * holds the email address that was (un)subscribed
     */
    email?: string | null;
    /**
     * if a SMS subscription status was changed, this
     * holds the phone number that was (un)subscribed,
     * in international format
     */
    intlPhoneNumber?: string | null;

    /**
     * their {@link Lead#_id}
     */
    leadId: string;

    /**
     * if done in team.bondvet.com, this will hold the user's
     * {@link User#_id} who performed the change
     */
    teamUserId?: string | null;

    /**
     * if the change was originally made on another client profile,
     * this will hold the client's {@link User#_id}
     */
    originalClientId?: string | null;

    /**
     * if the change was performed in Vetspire and on another
     * client's chart, this will hold the Vetspire client's ID,
     * on who's chart this action was performed
     */
    originalVetspireClientId?: string | null;
};

/**
 * collection: `leads.subscriptionStatusChanges`
 */
export type DbSubscriptionStatusChange = SubscriptionStatusChange & {
    /**
     * primary key
     */
    _id: string | import('mongodb').UUID;
};

export type SubscriptionStatusChangeInput = Pick<
    SubscriptionStatusChange,
    | 'type'
    | 'status'
    | 'timestamp'
    | 'source'
    | 'email'
    | 'intlPhoneNumber'
    | 'vetspireProviderRecordedName'
    | 'vetspireProviderId'
    | 'originalClientId'
    | 'originalVetspireClientId'
> & {
    // if the phone number isn't known in international format
    // we can provide it in "common" format
    phoneNumber?: string;
};

export const GraphQLSubscriptionStatusChangeSourceMap = {
    bookingFlow: 'booking-flow',
    clientApp: 'client-app',
    vetspireProvider: 'vetspire-provider',
    unknown: 'unknown',
} as const;

export const InverseGraphQLSubscriptionStatusChangeSourceMap = {
    'booking-flow': 'bookingFlow',
    'client-app': 'clientApp',
    'vetspire-provider': 'vetspireProvider',
    unknown: 'unknown',
} as const;

export type GraphQLSubscriptionStatusChangeSource =
    keyof typeof GraphQLSubscriptionStatusChangeSourceMap;

export type GraphQLSubscriptionStatusChangeInput = Omit<
    SubscriptionStatusChangeInput,
    'source'
> & {
    source: GraphQLSubscriptionStatusChangeSource;
};

/**
 * keeps track of Lead SMS updates and is used to
 * detect and break infinite update loops accross multiple
 * lead profiles with the same intlPhoneNumber
 *
 * collection: `leads.smsUpdates`
 */
export type LeadSmsUpdates = {
    /**
     * intl phone number that (would have) triggered the update
     */
    intlPhoneNumber: string;
    /**
     * date and time of the last update (attempt)
     */
    lastUpdateAt: Date;
    /**
     * date and time of the first update within a certain
     * period of time
     */
    firstUpdateAt: Date;
    /**
     * counter of the updates/update attempts
     */
    count: number;
    /**
     * list of the updates we performed or tried to perform
     */
    updates: readonly {
        originalLeadId: string;
        update: UpdateFilter<SmsStatusAndChanges>;
    }[];
};
