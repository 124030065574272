import * as React from 'react';
import {
    TeamDatabaseRoleResult,
    TeamDatabaseRoleVariables,
} from '@bondvet/types/website';
import { ClinicTeamMemberData } from '@bondvet/types/clinicTeam';
import { onValue, query } from 'firebase/database';

import { teamDatabaseRoleQuery } from 'api/bond/queries';
import useWebsiteQuery from './useWebsiteQuery';
import useViewerSettings from './useViewerSettings';
import useFirebaseContext from './firebase/useFirebaseContext';
import { MESSAGE_TARGET_NAVIGATION } from '../lib/backgroundMessages';
import { openToast } from '../lib/vetspireActions';

export default function useShowBioQuestionnaire() {
    const viewerSettings = useViewerSettings();
    const { getDatabaseRef } = useFirebaseContext();
    const [rtdbEmployee, setRtdbEmployee] =
        React.useState<ClinicTeamMemberData | null>(null);
    const [toastSent, setToastSent] = React.useState(false);

    const { loading, data: role } = useWebsiteQuery<
        TeamDatabaseRoleResult,
        TeamDatabaseRoleVariables
    >(teamDatabaseRoleQuery, {
        variables: {
            email: viewerSettings.viewer?.email ?? '',
        },
    });

    React.useEffect(() => {
        if (viewerSettings.viewer?.email) {
            getDatabaseRef('website/data/clinicTeam').then((ref) => {
                if (ref) {
                    onValue(query(ref), (snapshot) => {
                        const data: Record<string, ClinicTeamMemberData> =
                            snapshot.val();

                        const employeeData = Object.values(data).find(
                            (employee) => {
                                return (
                                    employee?.email ===
                                    viewerSettings.viewer?.email
                                );
                            },
                        );
                        if (employeeData) {
                            setRtdbEmployee(employeeData);
                        }
                    });
                }
            });
        }
    }, [getDatabaseRef, viewerSettings.viewer?.email]);

    const showQuestionnaire = React.useMemo(() => {
        if (!loading && role?.teamDatabaseRole?.bioType && rtdbEmployee) {
            if (rtdbEmployee.bio?.type === role.teamDatabaseRole.bioType) {
                if (
                    !rtdbEmployee.unapprovedBio?.type ||
                    rtdbEmployee.unapprovedBio?.type !==
                        role.teamDatabaseRole.bioType
                )
                    return false;
                return !rtdbEmployee.readyForReview;
            }

            if (
                rtdbEmployee.unapprovedBio?.type ===
                role.teamDatabaseRole.bioType
            ) {
                return !rtdbEmployee.readyForReview;
            }

            return true;
        }
        return false;
    }, [loading, role, rtdbEmployee]);

    React.useEffect(() => {
        if (showQuestionnaire && !toastSent) {
            setToastSent(true);
            const toast = {
                title: 'Please fill out your brief required questionnaire!',
                timeoutMs: 100000,
                category: 'bioQuestionnaire',
                text: '',
                icon: 'questionnaire',
                replaceExisting: false,
                onClickMessage: {
                    action: 'openQuestionnaire',
                    targets: [MESSAGE_TARGET_NAVIGATION],
                },
            };

            openToast(toast);
        }
    }, [showQuestionnaire, toastSent]);

    return showQuestionnaire;
}
