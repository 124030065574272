import * as React from 'react';
import useWebsiteQuery from 'hooks/useWebsiteQuery';
import {
    doctorQuestionsQuery,
    freeformBioSettingsQuery,
    teamDatabaseRoleQuery,
} from 'api/bond/queries';
import useViewerSettings from 'hooks/useViewerSettings';
import {
    DoctorQuestionsResult,
    TeamDatabaseRoleVariables,
    BioType,
    FreeformBioSettingsResult,
    TeamDatabaseRoleResult,
} from '@bondvet/types/website';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import useFirebaseContext from 'hooks/firebase/useFirebaseContext';
import { onValue, query, set } from 'firebase/database';
import {
    ClinicTeamMemberData,
    FreeformTeamMemberBio,
    QuestionnaireTeamMemberBio,
    TeamMemberBio,
} from '@bondvet/types/clinicTeam';

type FormValues = Record<string, string>;

type Question = {
    id: string;
    label: string;
    placeholder: string;
    maxCharacters: number;
};

export default function useQuestionnaireForm() {
    const viewerSettings = useViewerSettings();
    const translate = useTranslate();
    const { getDatabaseRef } = useFirebaseContext();
    const [id, setId] = React.useState<string | null>(null);

    const [formValues, setFormValues] = React.useState<FormValues>({});

    React.useEffect(() => {
        if (viewerSettings.viewer?.email) {
            getDatabaseRef('website/data/clinicTeam').then((ref) => {
                if (ref) {
                    onValue(query(ref), (snapshot) => {
                        const data: Record<string, ClinicTeamMemberData> =
                            snapshot.val();

                        const employeeData = Object.entries(data).find(
                            ([, employee]) => {
                                return (
                                    employee?.email ===
                                    viewerSettings.viewer?.email
                                );
                            },
                        );

                        if (employeeData) {
                            const [employeeId, employee] = employeeData;
                            setId(employeeId);

                            if (
                                employee?.unapprovedBio?.type ===
                                'questionnaire'
                            ) {
                                setFormValues(
                                    (
                                        employee.unapprovedBio as QuestionnaireTeamMemberBio
                                    ).questions,
                                );
                            } else if (
                                employee?.unapprovedBio?.type === 'freeform'
                            ) {
                                setFormValues({
                                    value: (
                                        employee.unapprovedBio as FreeformTeamMemberBio
                                    ).value,
                                });
                            }
                        }
                    });
                }
            });
        }
    }, [getDatabaseRef, viewerSettings.viewer?.email]);

    const { loading: questionsLoading, data: questionnaireData } =
        useWebsiteQuery<DoctorQuestionsResult>(doctorQuestionsQuery);
    const { loading: roleLoading, data: teamRole } = useWebsiteQuery<
        TeamDatabaseRoleResult,
        TeamDatabaseRoleVariables
    >(teamDatabaseRoleQuery, {
        variables: {
            email: viewerSettings.viewer?.email ?? '',
        },
    });

    const { loading: freeformSettingsLoading, data: freeformSettings } =
        useWebsiteQuery<FreeformBioSettingsResult>(freeformBioSettingsQuery);

    const editFormValues = React.useCallback((newFormValues: FormValues) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            ...newFormValues,
        }));
    }, []);

    const { bioType, questions } = React.useMemo<{
        bioType: BioType | null;
        questions: ReadonlyArray<Question>;
    }>(() => {
        if (
            !questionsLoading &&
            !roleLoading &&
            !freeformSettingsLoading &&
            teamRole?.teamDatabaseRole
        ) {
            if (teamRole.teamDatabaseRole.bioType) {
                if (teamRole.teamDatabaseRole.bioType === 'questionnaire') {
                    return {
                        questions:
                            questionnaireData?.doctorQuestions
                                ?.filter(({ display }) => !!display)
                                ?.sort((a, b) => a.orderId - b.orderId)
                                ?.map(
                                    ({
                                        _id,
                                        maxCharacters,
                                        employeeQuestion,
                                        placeholder,
                                    }) => ({
                                        id: _id,
                                        label: employeeQuestion,
                                        placeholder,
                                        maxCharacters,
                                    }),
                                ) ?? [],
                        bioType: teamRole.teamDatabaseRole.bioType,
                    };
                }

                return {
                    questions: [
                        {
                            id: 'value',
                            label: translate(
                                'vetspireExtension.bioQuestionnaire.freeformBioLabel',
                            ).toString(),
                            placeholder:
                                freeformSettings?.freeformBioSettings
                                    ?.placeholder ?? '',
                            maxCharacters:
                                freeformSettings?.freeformBioSettings
                                    ?.maxCharacters ?? 850,
                        },
                    ],
                    bioType: teamRole.teamDatabaseRole.bioType,
                };
            }
        }
        return {
            questions: [],
            bioType: null,
        };
    }, [
        questionnaireData,
        questionsLoading,
        freeformSettings,
        freeformSettingsLoading,
        teamRole,
        roleLoading,
        translate,
    ]);

    const handleSave = React.useCallback(
        async (submit?: boolean) => {
            if (id) {
                let unapprovedBio: TeamMemberBio | null = null;
                if (bioType === 'freeform') {
                    unapprovedBio = {
                        type: bioType,
                        value: formValues.value,
                    };
                }
                if (bioType === 'questionnaire') {
                    unapprovedBio = {
                        type: bioType,
                        questions: questions.reduce(
                            (acc, { id: questionId }) => ({
                                ...acc,
                                [questionId]: formValues[questionId],
                            }),
                            {},
                        ),
                    };
                }
                if (unapprovedBio) {
                    const ref = await getDatabaseRef(
                        `website/data/clinicTeam/${id}/unapprovedBio`,
                    );
                    if (ref) {
                        await set(ref, unapprovedBio);
                    }

                    const flagRef = await getDatabaseRef(
                        `website/data/clinicTeam/${id}/readyForReview`,
                    );
                    if (flagRef) {
                        await set(flagRef, !!submit);
                    }
                }
            }
        },
        [bioType, formValues, getDatabaseRef, id, questions],
    );

    const validationResult: Record<string, string> | null =
        React.useMemo(() => {
            if (bioType === 'freeform') {
                if (!formValues?.value?.length) {
                    return {
                        value: 'Required',
                    };
                }
                if (
                    formValues?.value?.length >
                    (freeformSettings?.freeformBioSettings?.maxCharacters ??
                        850)
                ) {
                    return { value: 'Above character limit' };
                }
            } else if (bioType === 'questionnaire') {
                const questionErrors = questions.reduce((acc, question) => {
                    if (!formValues[question.id]?.length) {
                        return {
                            ...acc,
                            [question.id]: 'Required',
                        };
                    }

                    if (
                        formValues[question.id].length > question.maxCharacters
                    ) {
                        return {
                            ...acc,
                            [question.id]: 'Above character limit',
                        };
                    }

                    return acc;
                }, {});
                if (Object.keys(questionErrors).length > 0) {
                    return questionErrors;
                }
            }
            return null;
        }, [bioType, formValues, freeformSettings, questions]);

    return {
        questions,
        formValues,
        editFormValues,
        bioType,
        validationResult,
        loading: questionsLoading || roleLoading || freeformSettingsLoading,
        handleSave,
    };
}
