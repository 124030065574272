import * as React from 'react';
import { gql } from '@apollo/client';
import { initializeApp } from 'firebase/app';

import {
    Auth,
    connectAuthEmulator,
    getAuth,
    onAuthStateChanged,
    signInWithCustomToken,
    signOut,
    User,
} from 'firebase/auth';
import {
    getDatabase,
    connectDatabaseEmulator,
    type Database,
    ref as databaseRef,
} from 'firebase/database';
import {
    collection,
    connectFirestoreEmulator,
    doc,
    type CollectionReference,
    type DocumentData,
    type DocumentReference,
    type Firestore,
    getFirestore,
} from 'firebase/firestore';
import {
    getStorage,
    connectStorageEmulator,
    type FirebaseStorage,
    ref as storageRef,
    getDownloadURL,
} from 'firebase/storage';

import { FirebaseContextData, FirebaseContextType } from './context';
import useAuthMutation from '../useAuthMutation';

export interface CreateFirebaseTokenResult {
    createFirebaseToken: string;
}

const createFirebaseTokenMutation = gql`
    mutation createFirebaseToken {
        createFirebaseToken
    }
`;

function connectToEmulatorInDevelopment(
    auth: Auth,
    database: Database,
    storage: FirebaseStorage,
    firestore: Firestore,
) {
    if (process.env.REACT_APP_USE_FIREBASE_EMULATORS === 'true') {
        console.info('connecting to emulators');
        connectAuthEmulator(auth, 'http://localhost:9099', {
            disableWarnings: true,
        });
        connectDatabaseEmulator(database, 'localhost', 9000);
        connectStorageEmulator(storage, 'localhost', 9199);
        connectFirestoreEmulator(firestore, 'localhost', 8080);
    }
}

export default function useFirebaseContextHandler(): FirebaseContextType {
    const [
        {
            firebase,
            user,
            database,
            storage,
            firestore,
            isInitializing,
            isInitialized,
        },
        setValue,
    ] = React.useState<FirebaseContextData>({
        firebase: null,
        user: null,
        database: null,
        storage: null,
        firestore: null,
        isInitializing: false,
        isInitialized: false,
        getCollection: () => {
            return Promise.resolve<null>(null);
        },
        getDocRef: () => {
            return Promise.resolve<null>(null);
        },
        getDatabaseRef: () => {
            return Promise.resolve<null>(null);
        },
        getStorageRef: () => {
            return Promise.resolve<null>(null);
        },
        getDownloadUrl: () => {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('not initialized');
        },
    });

    const [firebaseToken, setFirebaseToken] = React.useState<string | null>(
        null,
    );
    const [createFirebaseToken] = useAuthMutation<CreateFirebaseTokenResult>(
        createFirebaseTokenMutation,
    );

    React.useEffect(() => {
        const app = initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
                process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        });
        setValue((prev) => ({
            ...prev,
            firebase: app,
            database: getDatabase(app),
            storage: getStorage(app),
            firestore: getFirestore(app),
        }));
    }, []);

    React.useEffect(() => {
        if (firebase && database && storage && firestore) {
            const auth = getAuth();

            connectToEmulatorInDevelopment(auth, database, storage, firestore);
            const unsubscribe = onAuthStateChanged(
                auth,
                (newUser: User | null) => {
                    if (newUser) {
                        setValue((prev) => ({
                            ...prev,
                            user: newUser,
                            isInitializing: false,
                            isInitialized: true,
                        }));
                    } else {
                        setValue((prev) => ({
                            ...prev,
                            user: null,
                        }));
                    }
                },
            );

            return () => unsubscribe();
        }
        return undefined;
    }, [database, firebase, firestore, storage]);

    const signIntoFirebase = React.useCallback(async () => {
        let authToken = firebaseToken;

        if (!authToken) {
            const result = await createFirebaseToken();

            authToken = result.data?.createFirebaseToken || null;
            if (authToken) {
                setFirebaseToken(authToken);
            }
        }

        if (authToken && !firebaseToken && firebase) {
            const auth = getAuth(firebase);
            await signInWithCustomToken(auth, authToken);
        }

        return !!authToken;
    }, [createFirebaseToken, firebaseToken, firebase]);

    const initialize = React.useCallback(() => {
        if (!user) {
            signIntoFirebase().then();
        }
    }, [signIntoFirebase, user]);

    React.useEffect(() => {
        if (!user && !isInitializing) {
            setValue((prev) => ({
                ...prev,
                isInitializing: true,
            }));

            initialize();
        }
    }, [user, initialize, isInitializing]);

    const getCollection = React.useCallback(
        async <T extends DocumentData>(collectionName: string) => {
            if (firebase && (await signIntoFirebase())) {
                const db = getFirestore();
                return collection(db, collectionName) as CollectionReference<T>;
            }
            return null;
        },
        [firebase, signIntoFirebase],
    );

    const getDocRef = React.useCallback(
        async <T extends DocumentData>(
            collectionName: string,
            docId: string,
        ) => {
            if (firebase && (await signIntoFirebase())) {
                const db = getFirestore();
                return doc(db, collectionName, docId) as DocumentReference<T>;
            }
            return null;
        },
        [firebase, signIntoFirebase],
    );

    const getDatabaseRef = React.useCallback(
        async (path: string) => {
            if (database && (await signIntoFirebase())) {
                return databaseRef(database, path);
            }
            return null;
        },
        [database, signIntoFirebase],
    );

    const getStorageRef = React.useCallback(
        async (path: string) => {
            if (storage && (await signIntoFirebase())) {
                return storageRef(storage, path);
            }
            return null;
        },
        [signIntoFirebase, storage],
    );

    const getDownloadUrl = React.useCallback(
        (path: string) => {
            return getStorageRef(path).then((reference) => {
                if (reference) {
                    return getDownloadURL(reference);
                }

                throw new Error('not initialized');
            });
        },
        [getStorageRef],
    );

    async function signOutOfFirebase() {
        const auth = getAuth();
        await signOut(auth);
    }

    return {
        firebase,
        signIntoFirebase,
        signOutOfFirebase,
        user,
        getCollection,
        getDocRef,
        database,
        storage,
        firestore,
        getDatabaseRef,
        getStorageRef,
        getDownloadUrl,
        isInitializing,
        isInitialized,
    };
}
