import { Firestore } from 'firebase/firestore';
import * as React from 'react';
import { type FirebaseApp } from 'firebase/app';
import { type User } from 'firebase/auth';
import {
    type CollectionReference,
    type DocumentData,
    type DocumentReference,
} from '@firebase/firestore';
import { type Database, type DatabaseReference } from 'firebase/database';
import { type FirebaseStorage, type StorageReference } from 'firebase/storage';

export type FirebaseContextData = {
    firebase: null | FirebaseApp;
    user: null | User;
    getCollection: <T extends DocumentData>(
        collectionName: string,
    ) => Promise<CollectionReference<T> | null>;
    getDocRef: <T extends DocumentData>(
        collectionName: string,
        docId: string,
    ) => Promise<DocumentReference<T> | null>;
    getDatabaseRef: (path: string) => Promise<DatabaseReference | null>;
    getStorageRef: (path: string) => Promise<StorageReference | null>;
    getDownloadUrl: (path: string) => Promise<string>;
    database: null | Database;
    storage: null | FirebaseStorage;
    firestore: null | Firestore;
    isInitializing: boolean;
    isInitialized: boolean;
};

export type FirebaseContextUpdaters = {
    signIntoFirebase: () => Promise<boolean>;
    signOutOfFirebase: () => Promise<void>;
};

export type FirebaseContextType = FirebaseContextData & FirebaseContextUpdaters;
const FirebaseContext = React.createContext<FirebaseContextType>({
    firebase: null,
    user: null,
    database: null,
    storage: null,
    firestore: null,
    isInitializing: false,
    isInitialized: false,
    getCollection: () => {
        return Promise.resolve<null>(null);
    },
    getDocRef: () => {
        return Promise.resolve<null>(null);
    },
    getDatabaseRef: () => {
        return Promise.resolve<null>(null);
    },
    getStorageRef: () => {
        return Promise.resolve<null>(null);
    },
    getDownloadUrl: () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('not initialized');
    },
    signIntoFirebase: () => {
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => resolve(false));
    },
    signOutOfFirebase: () => {
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => resolve());
    },
});
export default FirebaseContext;
